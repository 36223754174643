.gx-app-login-content {
  padding-top: 70px;
  padding-bottom: 70px;
}

.gx-app-logo-content-bg:before {
  background-color: rgba(3, 143, 222, 0);
}

@media only screen and (max-width: 575px) {
  .gx-app-logo-content-bg {
    display: none;
  }
  .gx-app-logo-wid,
  .sign-in-text {
    color: #545454 !important;
  }
}

.table-card > .ant-card-body {
  padding: 0px;
}

.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 3px 8px;
}

.ant-table-pagination.ant-pagination {
  margin: 5px 0;
}

.full-width {
  width: 100%;
}

.pre-text-short {
  white-space: -moz-pre-wrap; /* Mozilla, supported since 1999 */
  white-space: -pre-wrap; /* Opera */
  white-space: -o-pre-wrap; /* Opera */
  white-space: pre-wrap; /* CSS3 - Text module (Candidate Recommendation) http://www.w3.org/TR/css3-text/#white-space */
  word-wrap: break-word; /* IE 5.5+ */
  padding-left: 5px;
  max-height: 80px;
}

.no-padding-card > div {
  padding: 5px !important;
}

.no-margin-tab > div {
  margin: 0px !important;
}

.float-left {
  float: left;
}

.expanded-table > tr > th {
  vertical-align: top;
  font-weight: 500;
  /* width: 220px; */
}